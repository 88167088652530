





































































































































import { ApiResource } from '@/types';
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  components: {
    WatchlistButton: () => import(/* webpackChunkName: "v-p-wlb" */ '@/components/profile/WatchlistButton.vue'),
  },
  data() {
    return {
      error: null as Error | null,
      showModal: false,
    };
  },
  computed: {
    ...mapGetters({
      list: 'profiles/WatchList',
      find: 'profiles/Find',
    }),
    watchlist(): ApiResource.Profile {
      const { list } = this;

      // Sort open to be first, then where waitlist is open

      return list
        .sort((a: ApiResource.Profile, b: ApiResource.Profile) => {
          /* eslint-disable camelcase */
          if (a.waitlist?.is_open && !b.waitlist?.is_open) return -1;
          if (!a.waitlist?.is_open && b.waitlist?.is_open) return 1;
          /* eslint-enable camelcase */
          return 0;
        })
        .sort((a: ApiResource.Profile, b: ApiResource.Profile) => {
          if (a.slots.is_open && !b.slots.is_open) return -1;
          if (!a.slots.is_open && b.slots.is_open) return 1;
          return 0;
        });
    },
    intendedToAdd(): ApiResource.Profile | undefined {
      if (!this.$route.query.add) return undefined;
      return this.find(this.$route.query.add as string);
    },
  },
  watch: {
    '$route.query.add': {
      immediate: true,
      async handler(PROFILE_ID) {
        if (!PROFILE_ID) return;

        await this.$store.dispatch('profiles/Find', { PROFILE_ID });
      },
    },
    intendedToAdd: {
      immediate: true,
      handler(profile) {
        if (profile) this.showModal = true;
      },
    },
    showModal: {
      immediate: true,
      handler(to, from) {
        if (to === false && from === true) this.$router.push({ query: { } });
      },
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    handleError(e: Error) {
      this.error = e;
    },
    async fetch(limit = 20, offset = 0, order = 'updated_at', direction = 'desc') {
      await this.$store.dispatch('profiles/Watchlist', {
        query: {
          limit, offset, order, direction,
        },
      }).then(({ data }) => {
        if (data.length === limit) this.fetch(limit, limit + offset, order, direction);
      }).catch(this.handleError);
    },
    async remove(BUSINESS_ID: string) {
      await this.$store.dispatch('profiles/WatchlistRemove', { BUSINESS_ID })
        .catch(this.handleError);
    },
  },
});
